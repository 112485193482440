import type { Api } from '@rialtic/api'
import { acceptHMRUpdate, defineStore } from 'pinia'
import { useActiveUser } from './activeUser'
import { useEditor } from './editor'

const createErrorMessage = (error: any): string => {
  const message =
    typeof error === 'string' ? error : error.data?.message || error.message

  if (message === 'Prisma Known Error' && error.data.error) {
    const paragraphs = `${error.data.error}`.split('\n')

    return paragraphs.pop()?.trim() || message
  }
  return message
}

export const useApi = defineStore('api', {
  state: () => {
    const { $auth0, $auth0Ready, $datadog } = useNuxtApp()
    const { getAccessTokenSilently } = $auth0()
    const apiClient = useApiClient()

    const $apiFetch = async <T>(url: string, opts?, bareApi = false) => {
      await $auth0Ready()

      return apiClient.fetch<T>(url, opts)
    }

    const editTypeState = reactive({
      error: '',
      loading: false,
      data: [] as Api.EditType[],
    })

    const editType = {
      getMany: async () => {
        editTypeState.error = ''
        editTypeState.loading = true

        try {
          const data = await $apiFetch<Api.EditType[]>('/edit-type', {})
          editTypeState.data = data
        } catch (error) {
          $datadog.addError(error)
          editTypeState.error =
            error instanceof Error ? error.message : (error as string)
        } finally {
          editTypeState.loading = false
        }
      },
    }

    const engineState = reactive({
      error: '',
      data: [] as Api.Engine[],
      loading: false,
    })

    const engine = {
      create: async (engine: Partial<Api.Engine>) => {
        engineState.loading = true

        const newEngine = {
          name: engine.name,
        }
        try {
          const data = await $apiFetch<Api.Engine>('/engine/', {
            method: 'POST',
            body: newEngine,
          })
          engineState.data.push(data)
          return data
        } catch (error) {
          const errorMessage =
            error instanceof Error ? error.message : (error as string)
          $datadog.addError(error)

          engineState.error = errorMessage
          throw new Error(errorMessage)
        } finally {
          engineState.loading = false
        }
      },

      delete: async (id: string) => {
        engineState.error = ''
        engineState.loading = true

        try {
          await $apiFetch<Api.Engine>(`/engine/${id}`, {
            method: 'DELETE',
          })
          engineState.data = engineState.data.filter((item) => item.id !== id)
        } catch (error) {
          $datadog.addError(error)
          engineState.error =
            error instanceof Error ? error.message : (error as string)
          throw new Error(error.message)
        } finally {
          engineState.loading = false
        }
      },

      getMany: async () => {
        engineState.loading = true

        try {
          const data = await $apiFetch<Api.Engine[]>('/engine', {})
          engineState.data = data
        } catch (error) {
          $datadog.addError(error)
          engineState.error =
            error instanceof Error ? error.message : (error as string)
        } finally {
          engineState.loading = false
        }
      },

      update: async (updatedEngine: Partial<Api.Engine>, id: string) => {
        engineState.error = ''
        engineState.loading = true

        try {
          const data = await $apiFetch<Api.Engine>(`/engine/${id}`, {
            method: 'PUT',
            body: updatedEngine,
          })
          engineState.data = engineState.data.map((item) =>
            item.id === id ? { ...item, ...updatedEngine } : item,
          )

          return data
        } catch (error) {
          $datadog.addError(error)
          engineState.error =
            error instanceof Error ? error.message : (error as string)
          await engine.getMany()
          throw new Error(error.message)
        } finally {
          engineState.loading = false
        }
      },
    }

    const policyState = reactive({
      error: '',
      loading: false,
      data: [] as Api.Policy[],
    })

    const policyEditTypesState = reactive({
      error: '',
      loading: false,
      data: [] as Api.PolicyEditType[],
    })

    const policy = {
      editType: {
        addToPolicy: async (editTypes: Api.EditType[], policy_id: string) => {
          policyEditTypesState.error = ''
          policyEditTypesState.loading = true

          try {
            const token = await getAccessTokenSilently()
            await policy.editType.getMany()

            const unrelatedPolicyEditTypes = [] as Api.PolicyEditType[]
            const currentPolicyEditTypes = [] as Api.PolicyEditType[]

            const postItems = [...editTypes]
            const deleteItems = [] as Api.PolicyEditType[]

            for (const policyEditType of policyEditTypesState.data) {
              if (policyEditType.policy_id !== policy_id) {
                unrelatedPolicyEditTypes.push(policyEditType)
              } else {
                currentPolicyEditTypes.push(policyEditType)
                let hasMatch = false

                if (postItems.length) {
                  postItems.forEach(({ id }, index) => {
                    if (id === policyEditType.edit_type_id) {
                      hasMatch = true
                      postItems.splice(index, 1)
                    }
                  })
                }

                if (!hasMatch) deleteItems.push(policyEditType)
              }
            }

            const optimisticResult = editTypes.map(
              (editTypeItem): Api.PolicyEditType => ({
                edit_type_id: editTypeItem.id,
                policy_id,
              }),
            )

            policyEditTypesState.data = [
              ...unrelatedPolicyEditTypes,
              ...optimisticResult,
            ]

            let results = [] as Api.PolicyEditType[]
            if (postItems.length) {
              results = await Promise.all(
                postItems.map(({ id }) => {
                  return $apiFetch<Api.PolicyEditType>('/policy-edit-type', {
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                    method: 'POST',
                    body: {
                      edit_type_id: id,
                      policy_id,
                    },
                  })
                }),
              )
            }

            if (deleteItems.length) {
              try {
                await Promise.all(
                  postItems.map(({ id }) => {
                    return $apiFetch<Api.PolicyEditType>('/policy-edit-type', {
                      headers: {
                        Authorization: `Bearer ${token}`,
                      },
                      method: 'DELETE',
                      body: {
                        edit_type_id: id,
                        policy_id,
                      },
                    })
                  }),
                )
              } catch (error) {
                // TODO: handle error
                $datadog.addError(error)
              }
            }

            return results
          } catch (error) {
            $datadog.addError(error)
            policyEditTypesState.error =
              error instanceof Error ? error.message : (error as string)
            await policy.editType.getMany().catch()
          } finally {
            policyEditTypesState.loading = false
          }
        },

        getMany: async () => {
          policyEditTypesState.error = ''
          policyEditTypesState.loading = true

          try {
            const data =
              await $apiFetch<Api.PolicyEditType[]>('/policy-edit-type')
            policyEditTypesState.data = data
          } catch (error) {
            $datadog.addError(error)
            policyEditTypesState.error =
              error instanceof Error ? error.message : (error as string)
          } finally {
            policyEditTypesState.loading = false
          }
        },
      },

      addReview: async (
        policy_id: string,
        status_id: string,
      ): Promise<Api.Policy | undefined> => {
        const activeUser = useActiveUser()
        policyState.loading = true
        try {
          await $apiFetch<Api.PolicyReview>('/policy-review', {
            method: 'POST',
            body: {
              policy: policy_id,
              status: status_id,
              reviewer: activeUser.email,
            },
          })
        } catch (error) {
          const errorMessage =
            error instanceof Error ? error.message : (error as string)
          $datadog.addError(error)

          policyState.error = errorMessage
          throw new Error(errorMessage)
        } finally {
          policyState.loading = false
          await policy.get(policy_id)
        }

        return policyState.data.find(({ id }) => id === policy_id)
      },

      create: async (policy) => {
        policyState.loading = true
        try {
          const data = await $apiFetch<Api.Policy>('/policy', {
            method: 'POST',
            body: policy,
          })

          policyState.data.push(data)

          const editor = useEditor()
          editor.pushToRecentPolicy(data.id)

          return data
        } catch (error) {
          if (error?.data?.message === 'Prisma Known Error') {
            const prismaError = error.data.error
            $datadog.addError(error, {
              type: 'prisma_error',
              policy,
              prisma: prismaError.join('\n'),
            })
            if (
              prismaError.includes(
                '  Unique constraint failed on the fields: (`name`)',
              )
            ) {
              policyState.error = 'Policy name needs to be unique'
              throw new Error(policyState.error)
            }
          }

          const errorMessage =
            error instanceof Error ? error.message : (error as string)
          $datadog.addError(error, { policy })

          policyState.error = errorMessage
          throw new Error(errorMessage)
        } finally {
          policyState.loading = false
        }
      },

      get: async (id: string) => {
        policyState.error = ''
        policyState.loading = true

        try {
          const data = await $apiFetch<Api.Policy>(`/policy/${id}`, {
            params: { include: true },
          })

          policyState.data = policyState.data.length
            ? policyState.data.map((item) =>
                item.id === data.id ? data : item,
              )
            : [data]

          return data
        } catch (error) {
          $datadog.addError(error)
          policyState.error =
            error instanceof Error ? error.message : (error as string)
        } finally {
          policyState.loading = false
        }
      },

      getMany: async () => {
        policyState.error = ''
        policyState.loading = true

        try {
          const tStart = performance.now()
          const data = await $apiFetch<Api.Policy[]>('/policy', {
            params: { include: true },
          })
          const tEnd = performance.now()
          $datadog.addTiming('fetch_policy_many', tEnd - tStart)

          policyState.data = data
        } catch (error) {
          $datadog.addError(error)
          policyState.error =
            error instanceof Error ? error.message : (error as string)
        } finally {
          policyState.loading = false
        }
      },

      update: async (updates: Partial<Api.Policy>, id: string) => {
        policyState.error = ''
        policyState.loading = true

        try {
          $datadog.addAction('update_policy', {
            id,
            updates,
          })
          const buildUpdatedPolicy = (current, updates) => {
            const extras = {} as Record<string, any>

            if (updates.topic_id) {
              extras.topic = topicState.data.find(
                ({ id }) => id === updates.topic_id,
              )
            }

            if (updates.edit_types) {
              console.log(updates.edit_types)
            }

            return {
              ...current,
              ...updates,
              ...extras,
            }
          }
          policyState.data = policyState.data.map((item) =>
            item.id === id ? buildUpdatedPolicy(item, updates) : item,
          )

          if (updates.edit_types) {
            updates.edit_types = updates.edit_types.map(({ edit_type_id }) => ({
              edit_type_id,
            })) as any
          }
          const data = await $apiFetch<Api.Policy>(`/policy/${id}`, {
            method: 'PUT',
            body: updates,
          })

          return data
        } catch (error) {
          const errorMessage = createErrorMessage(error)
          $datadog.addError(error)

          policyState.error = errorMessage
          throw new Error(errorMessage)
        } finally {
          policyState.loading = false
        }
      },
    }

    const reviewStatusState = reactive({
      error: '',
      loading: false,
      data: [] as Api.ReviewStatus[],
    })

    const reviewStatusCollection = computed(() =>
      [...reviewStatusState.data].reduce((state, { id, status }) => {
        state[id] = status
        return state
      }, {}),
    )

    const reviewStatus = {
      getMany: async () => {
        reviewStatusState.error = ''
        reviewStatusState.loading = true
        try {
          if (
            Array.isArray(reviewStatusState.data) &&
            reviewStatusState.data.length
          )
            return reviewStatusState.data

          const data = await $apiFetch<Api.ReviewStatus[]>('/review-status', {
            params: { include: true },
          })
          reviewStatusState.data = data
          return data
        } catch (error) {
          $datadog.addError(error)
          reviewStatusState.error =
            error instanceof Error ? error.message : (error as string)
        } finally {
          reviewStatusState.loading = false
        }
      },
    }

    const sourceDocumentState = reactive({
      error: '',
      loading: false,
      data: [] as Api.SourceDocument[],
    })

    const sourceDocument = {
      get: async (id: string) => {
        sourceDocumentState.error = ''
        sourceDocumentState.loading = true

        try {
          const data = await $apiFetch<Api.SourceDocument>(
            `/source-document/${id}`,
            {
              params: { include: true },
            },
          )

          sourceDocumentState.data = sourceDocumentState.data.length
            ? sourceDocumentState.data.map((item) =>
                item.hash === data.hash ? data : item,
              )
            : [data]

          return data
        } catch (error) {
          $datadog.addError(error)
          sourceDocumentState.error =
            error instanceof Error ? error.message : (error as string)
        } finally {
          sourceDocumentState.loading = false
        }
      },

      getMany: async () => {
        sourceDocumentState.error = ''
        sourceDocumentState.loading = true

        try {
          const data = await $apiFetch<Api.SourceDocument[]>(
            '/source-document',
            {
              params: {
                include: true,
              },
            },
          )
          sourceDocumentState.data = data.filter((item) => {
            return /^https:\/\/api-worker/g.test(item.internal_url)
          })
        } catch (error) {
          $datadog.addError(error)
          sourceDocumentState.error =
            error instanceof Error ? error.message : (error as string)
        } finally {
          sourceDocumentState.loading = false
        }
      },

      update: async (updates: Partial<Api.SourceDocument>, hash: string) => {
        sourceDocumentState.error = ''
        sourceDocumentState.loading = true

        try {
          sourceDocumentState.data = sourceDocumentState.data.map((item) =>
            item.hash === hash ? { ...item, ...updates } : item,
          )

          $datadog.addAction('update_source_document', {
            id: hash,
            updates,
          })

          const data = await $apiFetch<Api.SourceDocument>(
            `/source-document/${hash}`,
            {
              method: 'PUT',
              body: updates,
            },
          )

          return data
        } catch (error) {
          $datadog.addError(error)
          sourceDocumentState.error =
            error instanceof Error ? error.message : (error as string)
          await sourceDocument.getMany()
        } finally {
          sourceDocumentState.loading = false
        }
      },
    }

    const topicState = reactive({
      error: '',
      data: [] as Api.Topic[],
      loading: false,
    })

    const topic = {
      create: async (topic: Partial<Api.Topic>) => {
        topicState.loading = true

        const newTopic = {
          topic: topic.topic,
        }
        try {
          const data = await $apiFetch<Api.Topic>('/topic/', {
            method: 'POST',
            body: newTopic,
          })
          topicState.data.push(data)
          return data
        } catch (error) {
          const errorMessage =
            error instanceof Error ? error.message : (error as string)
          $datadog.addError(error)

          topicState.error = errorMessage
          throw new Error(errorMessage)
        } finally {
          topicState.loading = false
        }
      },

      delete: async (id: string) => {
        topicState.error = ''
        topicState.loading = true

        try {
          await $apiFetch<Api.Topic>(`/topic/${id}`, {
            method: 'DELETE',
          })
          topicState.data = topicState.data.filter((item) => item.id !== id)
        } catch (error) {
          $datadog.addError(error)
          topicState.error =
            error instanceof Error ? error.message : (error as string)
          throw new Error(error.message)
        } finally {
          topicState.loading = false
        }
      },

      getMany: async () => {
        topicState.loading = true

        try {
          const data = await $apiFetch<Api.Topic[]>('/topic', {})
          topicState.data = data
        } catch (error) {
          $datadog.addError(error)
          topicState.error =
            error instanceof Error ? error.message : (error as string)
        } finally {
          topicState.loading = false
        }
      },

      update: async (updatedTopic: Partial<Api.Topic>, id: string) => {
        topicState.error = ''
        topicState.loading = true

        try {
          const data = await $apiFetch<Api.Topic>(`/topic/${id}`, {
            method: 'PUT',
            body: updatedTopic,
          })
          topicState.data = topicState.data.map((item) =>
            item.id === id ? { ...item, ...updatedTopic } : item,
          )

          return data
        } catch (error) {
          $datadog.addError(error)
          topicState.error =
            error instanceof Error ? error.message : (error as string)
          await topic.getMany()
          throw new Error(error.message)
        } finally {
          topicState.loading = false
        }
      },
    }

    return {
      fetch: $apiFetch,

      editType,
      editTypeState,

      engine,
      engineState,

      policy,
      policyState,
      policyEditTypesState,

      reviewStatus,
      reviewStatusState,
      reviewStatusCollection,

      sourceDocument,
      sourceDocumentState,

      topic,
      topicState,
    }
  },

  getters: {
    getPolicyById: (state) => {
      return (policyId: string) =>
        state.policyState.data.find((policy) => policy.id === policyId) ||
        ({} as Api.Policy)
    },
  },

  actions: {
    setPolicies(policies: Api.Policy[]) {
      this.policyState.data = policies
    },
  },
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useApi, import.meta.hot))
